.info-left {
    text-align: right !important;
    margin-right: 20px;
}

.info-right {
    text-align: left !important;
    margin-left: 20px;
}

.logo-container {
    width: 200px;
    height: 200px;
    display: inline-block;
}

.logo-content {
    width: 150px;
    padding: 10px;
}

@media (max-width: 575px) {
    .info-left {
        text-align: center !important;
        margin-right: 0;
        margin-bottom: 50px;
    }

    .info-right {
        text-align: center !important;
        margin-left: 0;
        margin-bottom: 50px;
    }
}

.captcha * {
    margin: auto;
}

.stats-panel {
    margin-top: 20px;
    margin-bottom: 60px;
}

.tab-content {
    padding-top: 20px !important;
}
.App {
    text-align: center;
}

.used-by {
    margin-top: 160px !important;
    margin-bottom: 60px !important;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

.highlight {
    background-color: #fcd3d8;
    color: #721c24;
}

.download-note {
    padding-top: 20px;
    padding-bottom: 20px;
}

.back-button {
    margin-top: 10px;
}

.download-list li > a {
    color: black;
}

.sendmessage-button {
    margin-top: 10px;
    padding-left: 10px;
    background-color: #ffa726 !important;
    border-color: #ff9800 !important;
}

.cancel-message-button {
    margin-top: 10px;
    margin-left: 10px;
}

.view-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: lightgray;
    padding: 10px;
    border-width: 1px;
    border-color: #ced4da;

    border-style: solid;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-left-width: 0;
}

.view-password :hover {
    cursor: pointer;
}

.orange-button {
    background-color: orange !important;
    border-width: 0px !important;
    color: white !important;
}

.disabled-checkbox {
    color: gray;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input {
    border-radius: 4px;
    padding: 8px;
    width: 100%;
    border-color: rgba(0, 0, 0, 0.1);
}

.react-datepicker__input-container input:focus {
    border-style: none;
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
