body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;  
  font-weight: 300; 
}

.form-check {
  margin-left: 1.25em;
  padding-left: 0 !important; 
}